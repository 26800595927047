import { ListItem, OrderedList, UnorderedList } from "carbon-components-react";
import * as React from "react";

const Coc = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            Plans must develop a process to notify Continuing Care patients
            (defined below) when their provider or facility leaves the network.
          </ListItem>
          <ListItem>
            Continuing Care patients are defined as someone:
            <OrderedList
              className="paddingLeft09"
              style={{ marginTop: "1rem" }}
            >
              <ListItem>
                undergoing a course of treatment for a serious and complex
                condition from a specific provider,
              </ListItem>
              <ListItem>
                undergoing a course of institutional or in-patient care from a
                specific provider,
              </ListItem>
              <ListItem>
                scheduled to undergo non-elective surgery from a specific
                provider, including receipt of postoperative care with respect
                to the surgery,
              </ListItem>
              <ListItem>
                pregnant and undergoing a course of treatment for the pregnancy
                from a specific provider, or
              </ListItem>
              <ListItem>
                or was determined to be terminally ill and is receiving
                treatment for such illness from a specific provider.
              </ListItem>
            </OrderedList>
          </ListItem>
          <ListItem>
            Plans must provide in-network benefits for 90 days, from the point
            of notification to the member, or until the course of treatment is
            complete.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            For some plans, the most difficult aspect of implementing this
            process will be finding a way to adjudicating claims as in-network
            once the provider has left the network. Different claims systems
            have varying capabilities to handle this situation.
          </ListItem>
          <ListItem>
            Plans will also need to ensure they are ready to consume network
            termination files if they are renting a third-party network.
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default Coc;
