import * as React from "react";

import { Content } from "carbon-components-react";
import { Checkbox32 } from "@carbon/icons-react";

const KeyQuestions = () => {
  return (
    <>
      <div id="keyQuestions" className="anchorOffset" />
      <Content>
        <div className="bx--row">
          <div className="bx--col-lg-2"></div>
          <div className="bx--col-lg-12">
            <h2 className="spacing09 h2">Key Questions for Health Plans</h2>
            <p className="contentText">
              The following questions summarize essential elements that plans,
              TPAs, and vendors must have in place prior to the compliance
              deadlines set by the federal departments.
            </p>

            <div className="questions contentText">
              <Checkbox32 />
              <p>
                Do you have an NQTL analysis in place and ready to be shared
                upon request?
              </p>
            </div>
            <div className="questions contentText">
              <Checkbox32 />
              <p>Are you prepared to adjudicate NSA claims appropriately?</p>
            </div>
            <div className="questions contentText">
              <Checkbox32 />
              <p>
                Do you have a QPA methodology in place and are you prepared to
                share it upon request?
              </p>
            </div>
            <div className="questions contentText">
              <Checkbox32 />
              <p>
                Are you prepared to receive IDR requests from
                providers/facilities and manage the arbitration process?
              </p>
            </div>
            <div className="questions contentText">
              <Checkbox32 />
              <p>
                Have you updated plan documents, ID cards, EOBs, and EOPs with
                the required information?
              </p>
            </div>
            <div className="questions contentText">
              <Checkbox32 />
              <p>
                Are you verifying the accuracy of your provider directory every
                90 days? Are you prepared to update the directory within 48
                hours of receiving notification of a provider/facility
                termination?
              </p>
            </div>
            <div className="questions contentText">
              <Checkbox32 />
              <p>
                Are you prepared to notify members of their Continuity of Care
                coverage, as appropriate, and adjudicate out-of-network claims
                as in-network for 90-days?
              </p>
            </div>
            <div className="questions contentText">
              <Checkbox32 />
              <p>
                Will you be prepared to publish the In-Network and Allowed
                Amount machine readable rate files by 7/1/2022?
              </p>
            </div>
            <div className="questions contentText">
              <Checkbox32 />
              <p>
                Will you have a price transparency tool in place by 1/1/2023?
                The tool must provide an accurate estimate of care for any
                item/service by any in-network provider, and include real-time
                data on deductible and cost-share.
              </p>
            </div>
          </div>
          <div className="bx--col-lg-2"></div>
        </div>
      </Content>
    </>
  );
};

export default KeyQuestions;
