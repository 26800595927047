import * as React from "react";

import FooterComponent from "../components/footer";
import HeaderComponent from "../components/header";
import RegTableComponent from "../components/regTable";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "carbon-components-react";
import { ArrowRight20 } from "@carbon/icons-react";
import KeyQuestions from "../components/keyQuestions";
import TransparencyHelp from "../components/transparencyHelp";
import KeyRequirements from "../components/keyRequirements";
import SEO from "../components/seo";
import toolkitSeoImage from "../images/transparency_toolkit_og_image.png";
import ExpertButtonComponent from "../components/expertButton";

const Transparency = () => {
  return (
    <>
      <SEO
        title="Transparency Toolkit | LaunchPad Health"
        description="A toolkit on NSA, CAA, and TiC for health plans, TPAs, networks, and third-party vendors."
        image={{ src: toolkitSeoImage, width: 1200, height: 627 }}
      />
      <header>
        <HeaderComponent />
      </header>
      <ExpertButtonComponent />
      <div className="leadSpaceMargin">
        <div className="bx--grid greyBackground transparencyCover spacing09">
          <div className="bx--row" style={{ minHeight: "35rem" }}>
            <div className="bx--col-lg-8 bx--col-md-0 bx--col-sm-0 transparencyImage"></div>
            <div className="bx--col-lg-8 bx--col-md-8 bx--col-sm-4 transparencyContent">
              <h1 className="toolkitTitle spacing09">
                The Price Transparency Toolkit
              </h1>
              <div>
                <p className="toolkitSubtitle spacing05">
                  We've gathered key information on the Transparency in Coverage
                  (TiC) rules, No Surprises Act (NSA), and the broader
                  Consolidated Appropriations Act of 2021 (CAA) to help you
                  understand their potential impact on your organization.
                </p>
                <p className="spacing05 transparencyAnchors">
                  <AnchorLink to="/transparency-toolkit#keyRequirements">
                    <Link
                      className="spacing03"
                      size="lg"
                      renderIcon={ArrowRight20}
                    >
                      Requirements
                    </Link>
                  </AnchorLink>
                  <AnchorLink to="/transparency-toolkit#details">
                    <Link
                      className="spacing03"
                      size="lg"
                      renderIcon={ArrowRight20}
                    >
                      Details
                    </Link>
                  </AnchorLink>
                  <AnchorLink to="/transparency-toolkit#keyQuestions">
                    <Link
                      className="spacing03"
                      size="lg"
                      renderIcon={ArrowRight20}
                    >
                      Questions
                    </Link>
                  </AnchorLink>
                  <AnchorLink to="/transparency-toolkit#experts">
                    <Link
                      className="spacing03"
                      size="lg"
                      renderIcon={ArrowRight20}
                    >
                      Experts
                    </Link>
                  </AnchorLink>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bx--grid">
          <KeyRequirements />
          <div className="spacing09"></div>
          <RegTableComponent />
          <div className="spacing09"></div>
          <KeyQuestions />
          <div className="spacing09"></div>
          <TransparencyHelp />
        </div>
      </div>
      <footer>
        <FooterComponent />
      </footer>
    </>
  );
};

export default Transparency;
