import { ListItem, UnorderedList, Link } from "carbon-components-react";
import * as React from "react";

const DrugCostReporting = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            Plans must submit an annual disclosure containing various data
            points on its membership, including the cost of prescription drugs
            for the plan, as well as its impact on member premiums. See the link
            below for a detailed list of items.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            While this may appear to be a report the plan’s PBM should generate,
            the required data elements likely demand partnership between the TPA
            and PBM to successfully submit the report. We recommend initiating
            discussions with both entities as soon as possible.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Resources:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            <Link href="https://www.cms.gov/httpswwwcmsgovregulations-and-guidancelegislationpaperworkreductionactof1995pra-listing/cms-10788">
              CMS Prescription Drug and Health Care Spending Guide
            </Link>
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default DrugCostReporting;
