import { ListItem, UnorderedList, Link } from "carbon-components-react";
import * as React from "react";

const AllowedAmountFile = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            This file must include historical allowed amounts for items and
            services furnished by out-of-network providers, as well as billed
            charges, and be published on the plan's website monthly.
          </ListItem>
          <ListItem>
            The data must span a 90-day period beginning 180 days prior to the
            publication date and be prepared in a machine-readable format (JSON,
            CSV, or XML).
          </ListItem>
          <ListItem>
            This file must be published at the plan sponsor level. However, some
            plans may not have enough claims data to meet a claim threshold the
            federal departments have established to maintain patient privacy. In
            these instances, plans may ask their TPA to produce this file across
            the TPA's book of business.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            Plans should consider how the claims threshold established by the
            federal departments affects their Allowed Amount file and determine
            in which cases they have sufficient data to report. Plans have been
            provided guidance by the federal departments on how to develop the
            file. See the link below.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Resources:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            <Link href="https://github.com/CMSgov/price-transparency-guide">
              CMS GitHub Price Transparency Rate File Guide
            </Link>
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default AllowedAmountFile;
