import { ListItem, UnorderedList } from "carbon-components-react";
import * as React from "react";

const AirAmbulance = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            Plans must report their air ambulance claims data to the Department
            of Health and Human Services for two consecutive years, 2023 and
            2024, starting 90 days after the end of the year in which the claims
            were incurred. All 2022 claims would be reported on 3/31/2023 and
            all 2023 claims would be reported on 3/30/2024.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            We recommend plans await publication of the final rules before
            implementing a process to provider the data requested.
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default AirAmbulance;
