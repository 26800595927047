import * as React from "react";

import { Link, Tag, TooltipDefinition } from "carbon-components-react";

import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
} from "carbon-components-react";

import SurpriseBilling from "./expandedDescriptions/surpriseBilling";
import Qpa from "./expandedDescriptions/qpa";
import BalanceBillingNotice from "./expandedDescriptions/balanceBillingNotice";
import PatientWaiver from "./expandedDescriptions/patientWaiver";
import Idr from "./expandedDescriptions/idr";
import IdCards from "./expandedDescriptions/idCards";
import Nqtl from "./expandedDescriptions/nqtl";
import ProviderDirectory from "./expandedDescriptions/providerDirectory";
import Coc from "./expandedDescriptions/coc";
import GagClauseProhibition from "./expandedDescriptions/gagClauseProhibition";
import GagClauseAttestation from "./expandedDescriptions/gagClauseAttestation";
import AirAmbulance from "./expandedDescriptions/airAmbulance";
import DrugCostReporting from "./expandedDescriptions/drugCostReporting";
import TransparencyTool from "./expandedDescriptions/transparencyTool";
import Aeob from "./expandedDescriptions/aeob";
import PrescriptionFile from "./expandedDescriptions/prescriptionFile";
import CompensationDisclosure from "./expandedDescriptions/compensationDisclosure";
import InNetworkFile from "./expandedDescriptions/inNetworkFile";
import AllowedAmountFile from "./expandedDescriptions/allowedAmountFile";
import ProviderNotice from "./expandedDescriptions/providerNotice";

const LevelOfCompliance = (props) => {
  if (props.text === "Full Compliance") {
    return (
      <TooltipDefinition
        align="end"
        direction="left"
        tabIndex={0}
        tooltipText="Rulemaking is done for now. The departments expect full compliance by the enforcement deadline."
      >
        {props.text}
      </TooltipDefinition>
    );
  } else if (props.text === "Good-Faith Effort") {
    return (
      <TooltipDefinition
        align="end"
        direction="left"
        tabIndex={0}
        tooltipText="Rulemaking is incomplete. The departments expect compliance using a good-faith interpretation of the law."
      >
        {props.text}
      </TooltipDefinition>
    );
  } else if (props.text === "Pending") {
    return (
      <TooltipDefinition
        align="end"
        direction="left"
        tabIndex={0}
        tooltipText="Rulemaking is likely to occur before the enforcement deadline. Plans are advised to await further guidance."
      >
        {props.text}
      </TooltipDefinition>
    );
  } else {
    return (
      <TooltipDefinition
        align="end"
        direction="left"
        tabIndex={0}
        tooltipText="No guidance at this time"
      >
        {props.text}
      </TooltipDefinition>
    );
  }
};

const headerData = [
  {
    key: "law",
    header: "Law",
  },
  {
    key: "requirement",
    header: "Requirement",
  },
  {
    key: "rulemaking_status",
    header: "Rulemaking Status",
  },
  {
    key: "rulemaking_details",
    header: "Rulemaking Details",
  },
  {
    key: "implementation_deadline",
    header: "Required for Plan Year On/After",
  },
  {
    key: "enforcement_begins",
    header: "Enforcement Begins",
  },
  {
    key: "levelof_compliance",
    header: "Level of Compliance",
  },
];

const rowData = [
  {
    id: "a",
    law: "CAA - NSA",
    requirement: "Prohibition on Surprise Balance Billing",
    rulemaking_status: (
      <Tag type="green" size="md" title="Complete">
        Complete
      </Tag>
    ),
    rulemaking_details: (
      <Link
        href="https://www.federalregister.gov/d/2021-14379"
        target="_blank"
        alt="External link to July 2021 IFR"
      >
        July 2021 IFR
      </Link>
    ),
    implementation_deadline: "1/1/2022",
    enforcement_begins: "1/1/2022",
    levelof_compliance: <LevelOfCompliance text="Full Compliance" />,
    expandDescription: <SurpriseBilling />,
  },
  {
    id: "b",
    law: "CAA - NSA",
    requirement: "Qualified Payment Amount (QPA)",
    rulemaking_status: (
      <Tag type="magenta" size="md" title="Active Litigation">
        Complete, but Active Litigation
      </Tag>
    ),
    rulemaking_details: (
      <Link
        href="https://www.federalregister.gov/d/2021-14379"
        target="_blank"
        alt="External link to July 2021 IFR"
      >
        July 2021 IFR
      </Link>
    ),
    implementation_deadline: "1/1/2022",
    enforcement_begins: "1/1/2022",
    levelof_compliance: <LevelOfCompliance text="Full Compliance" />,
    expandDescription: <Qpa />,
  },
  {
    id: "c",
    law: "CAA - NSA",
    requirement: "Consumer Notice on Balance Billing Protections",
    rulemaking_status: (
      <Tag type="green" size="md" title="Complete">
        Complete
      </Tag>
    ),
    rulemaking_details: (
      <Link
        href="https://www.federalregister.gov/d/2021-14379"
        target="_blank"
        alt="External link to July 2021 IFR"
      >
        July 2021 IFR
      </Link>
    ),
    implementation_deadline: "1/1/2022",
    enforcement_begins: "1/1/2022",
    levelof_compliance: <LevelOfCompliance text="Good-Faith Effort" />,
    expandDescription: <BalanceBillingNotice />,
  },
  {
    id: "z",
    law: "CAA - NSA",
    requirement: "Provider Notice on QPA and Negotiation Contact",
    rulemaking_status: (
      <Tag type="green" size="md" title="Complete">
        Complete
      </Tag>
    ),
    rulemaking_details: (
      <Link
        href="https://www.federalregister.gov/d/2021-14379"
        target="_blank"
        alt="External link to July 2021 IFR"
      >
        July 2021 IFR
      </Link>
    ),
    implementation_deadline: "1/1/2022",
    enforcement_begins: "1/1/2022",
    levelof_compliance: <LevelOfCompliance text="Full Compliance" />,
    expandDescription: <ProviderNotice />,
  },
  {
    id: "d",
    law: "CAA - NSA",
    requirement: "Patient Waiver of Balance Billing Protections",
    rulemaking_status: (
      <Tag type="green" size="md" title="Complete">
        Complete
      </Tag>
    ),
    rulemaking_details: (
      <Link
        href="https://www.federalregister.gov/d/2021-14379"
        target="_blank"
        alt="External link to July 2021 IFR"
      >
        July 2021 IFR
      </Link>
    ),
    implementation_deadline: "1/1/2022",
    enforcement_begins: "1/1/2022",
    levelof_compliance: <LevelOfCompliance text="Full Compliance" />,
    expandDescription: <PatientWaiver />,
  },
  {
    id: "e",
    law: "CAA - NSA",
    requirement: "Independent Dispute Resolution (IDR)",
    rulemaking_status: (
      <Tag type="magenta" size="md" title="Active Litigation">
        Complete, but Active Litigation
      </Tag>
    ),
    rulemaking_details: (
      <Link
        href="https://www.federalregister.gov/d/2021-21441"
        target="_blank"
        alt="External link to October 2021 IFR"
      >
        Oct 2021 IFR
      </Link>
    ),
    implementation_deadline: "1/1/2022",
    enforcement_begins: "1/1/2022",
    levelof_compliance: <LevelOfCompliance text="Full Compliance" />,
    expandDescription: <Idr />,
  },
  {
    id: "f",
    law: "CAA - NSA",
    requirement: "ID Card Transparency",
    rulemaking_status: (
      <Tag type="green" size="md" title="Self-Implementing">
        Self-Implementing
      </Tag>
    ),
    rulemaking_details: "N/A",
    implementation_deadline: "1/1/2022",
    enforcement_begins: "1/1/2022",
    levelof_compliance: <LevelOfCompliance text="Good-Faith Effort" />,
    expandDescription: <IdCards />,
  },
  {
    id: "g",
    law: "CAA - Transparency",
    requirement: "Non-Quantitative Treatment Limitation Requirements",
    rulemaking_status: (
      <Tag type="green" size="md" title="Self-Implementing">
        Self-Implementing
      </Tag>
    ),
    rulemaking_details: "N/A",
    implementation_deadline: "2/10/2021",
    enforcement_begins: "2/10/2021",
    levelof_compliance: <LevelOfCompliance text="Full Compliance" />,
    expandDescription: <Nqtl />,
  },
  {
    id: "h",
    law: "CAA - NSA",
    requirement: "Updated and Accurate Provider Directories",
    rulemaking_status: (
      <Tag type="cool-gray" size="md" title="Timeline Unclear">
        Timeline Unclear
      </Tag>
    ),
    rulemaking_details: "N/A",
    implementation_deadline: "1/1/2022",
    enforcement_begins: "1/1/2022",
    levelof_compliance: <LevelOfCompliance text="Good-Faith Effort" />,
    expandDescription: <ProviderDirectory />,
  },
  {
    id: "i",
    law: "CAA - NSA",
    requirement: "Continuity of Care (CoC)",
    rulemaking_status: (
      <Tag type="cool-gray" size="md" title="Timeline Unclear">
        Timeline Unclear
      </Tag>
    ),
    rulemaking_details: "N/A",
    implementation_deadline: "1/1/2022",
    enforcement_begins: "1/1/2022",
    levelof_compliance: <LevelOfCompliance text="Good-Faith Effort" />,
    expandDescription: <Coc />,
  },
  {
    id: "j",
    law: "CAA - Transparency",
    requirement: "Prohibition of Gag Clauses",
    rulemaking_status: (
      <Tag type="green" size="md" title="Self-Implementing">
        Self-Implementing
      </Tag>
    ),
    rulemaking_details: "N/A",
    implementation_deadline: "1/1/2022",
    enforcement_begins: "1/1/2022",
    levelof_compliance: <LevelOfCompliance text="Good-Faith Effort" />,
    expandDescription: <GagClauseProhibition />,
  },
  {
    id: "k",
    law: "CAA - Transparency",
    requirement: "Annual Attestation on Gag Clauses",
    rulemaking_status: (
      <Tag type="cool-gray" size="md" title="Timeline Unclear">
        Timeline Unclear
      </Tag>
    ),
    rulemaking_details: "N/A",
    implementation_deadline: "10/31/2022",
    enforcement_begins: "10/31/2022",
    levelof_compliance: <LevelOfCompliance text="Pending" />,
    expandDescription: <GagClauseAttestation />,
  },
  {
    id: "l",
    law: "TiC",
    requirement: "In-Network Rate File",
    rulemaking_status: (
      <Tag type="green" size="md" title="Complete">
        Complete
      </Tag>
    ),
    rulemaking_details: (
      <Link
        href="https://www.federalregister.gov/d/2020-24591"
        target="_blank"
        alt="External link to Nov 2020 FR"
      >
        Nov 2020 FR
      </Link>
    ),
    implementation_deadline: "1/1/2022",
    enforcement_begins: "7/1/2022",
    levelof_compliance: <LevelOfCompliance text="Full Compliance" />,
    expandDescription: <InNetworkFile />,
  },
  {
    id: "m",
    law: "TiC",
    requirement: "Allowed Amount Rate File",
    rulemaking_status: (
      <Tag type="green" size="md" title="Complete">
        Complete
      </Tag>
    ),
    rulemaking_details: (
      <Link
        href="https://www.federalregister.gov/d/2020-24591"
        target="_blank"
        alt="External link to Nov 2020 FR"
      >
        Nov 2020 FR
      </Link>
    ),
    implementation_deadline: "1/1/2022",
    enforcement_begins: "7/1/2022",
    levelof_compliance: <LevelOfCompliance text="Full Compliance" />,
    expandDescription: <AllowedAmountFile />,
  },
  {
    id: "n",
    law: "CAA - NSA",
    requirement: "Reporting on Air Ambulance Services",
    rulemaking_status: (
      <Tag type="purple" size="md" title="Awaiting Final Rules">
        Awaiting Final Rules
      </Tag>
    ),
    rulemaking_details: (
      <Link
        href="https://www.federalregister.gov/d/2021-19797"
        target="_blank"
        alt="External link to Sept 2021 NPRM"
      >
        Sept 2021 NPRM
      </Link>
    ),
    implementation_deadline: "3/31/2023",
    enforcement_begins: "3/31/2023",
    levelof_compliance: <LevelOfCompliance text="N/A" />,
    expandDescription: <AirAmbulance />,
  },
  {
    id: "o",
    law: "CAA - Transparency",
    requirement: "Reporting on Pharmacy Benefits and Drug Costs",
    rulemaking_status: (
      <Tag type="green" size="md" title="Complete">
        Complete
      </Tag>
    ),
    rulemaking_details: (
      <Link
        href="https://www.federalregister.gov/d/2021-25183"
        target="_blank"
        alt="External link to Nov 2021 IFR"
      >
        Nov 2021 IFR
      </Link>
    ),
    implementation_deadline: "12/27/2021",
    enforcement_begins: "12/27/2022",
    levelof_compliance: <LevelOfCompliance text="Full Compliance" />,
    expandDescription: <DrugCostReporting />,
  },
  {
    id: "p",
    law: "TiC",
    requirement: "Price Transparency Tool",
    rulemaking_status: (
      <Tag type="green" size="md" title="Complete">
        Complete
      </Tag>
    ),
    rulemaking_details: (
      <Link
        href="https://www.federalregister.gov/d/2020-24591"
        target="_blank"
        alt="External link to Nov 2020 FR"
      >
        Nov 2020 FR
      </Link>
    ),
    implementation_deadline: "1/1/2023",
    enforcement_begins: "1/1/2023",
    levelof_compliance: <LevelOfCompliance text="Full Compliance" />,
    expandDescription: <TransparencyTool />,
  },
  {
    id: "q",
    law: "CAA - NSA",
    requirement: "Advanced Explanation of Benefits (AEOB)",
    rulemaking_status: (
      <Tag type="red" size="md" title="On-Hold">
        On-Hold
      </Tag>
    ),
    rulemaking_details: "N/A",
    implementation_deadline: "N/A",
    enforcement_begins: "N/A",
    levelof_compliance: <LevelOfCompliance text="N/A" />,
    expandDescription: <Aeob />,
  },
  {
    id: "r",
    law: "TiC",
    requirement: "Prescription Drug Rate File",
    rulemaking_status: (
      <Tag type="red" size="md" title="On-Hold">
        On-Hold
      </Tag>
    ),
    rulemaking_details: "N/A",
    implementation_deadline: "N/A",
    enforcement_begins: "N/A",
    levelof_compliance: <LevelOfCompliance text="N/A" />,
    expandDescription: <PrescriptionFile />,
  },
  // {
  //   id: "s",
  //   law: "CAA - Transparency",
  //   requirement: "Compensation Disclosure for Brokers and Consultants",
  //   rulemaking_status: (
  //     <Tag type="purple" size="md" title="Seeking Comment">
  //       Seeking Comment
  //     </Tag>
  //   ),
  //   rulemaking_details: (
  //     <Link
  //       href="https://www.federalregister.gov/d/2021-19797"
  //       target="_blank"
  //       alt="External link to Sept 2021 NPRM"
  //     >
  //       Sept 2021 NPRM
  //     </Link>
  //   ),
  //   implementation_deadline: "12/27/2021",
  //   enforcement_begins: "N/A",
  //   levelof_compliance: <LevelOfCompliance text="N/A" />,
  //   expandDescription: <CompensationDisclosure />,
  // },
  // {
  //   id: "t",
  //   law: "CAA - NSA",
  //   requirement: "Provider Nondiscrimination",
  //   rulemaking_status: (
  //     <Tag type="cool-gray" size="md" title="Coming in 2022">
  //       Coming in 2022
  //     </Tag>
  //   ),
  //   rulemaking_details: "N/A",
  //   implementation_deadline: "N/A",
  //   enforcement_begins: "N/A",
  //   levelof_compliance: <LevelOfCompliance text="N/A" />,
  //   expandDescription: "Hello Mike",
  // },
];

const RegTableComponent = () => {
  const getRowTitle = (rowId) => {
    const row = rowData.find(({ id }) => id === rowId);
    return row ? row.requirement : "";
  };

  const getRowDescription = (rowId) => {
    const row = rowData.find(({ id }) => id === rowId);
    return row ? row.expandDescription : "";
  };

  return (
    <>
      <div id="details" className="anchorOffset" />
      <h2 className="spacing09 h2 detailsCover">
        Due Dates and Rulemaking Status by Regulation
      </h2>
      <DataTable rows={rowData} headers={headerData}>
        {({ rows, headers, getHeaderProps, getTableProps, getRowProps }) => (
          <TableContainer title="Last updated on 01/10/2023">
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  <TableExpandHeader id="expand" />
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <React.Fragment key={row.id}>
                    <TableExpandRow {...getRowProps({ row })}>
                      {row.cells.map((cell) => (
                        <TableCell key={cell.id}>{cell.value}</TableCell>
                      ))}
                    </TableExpandRow>

                    <TableExpandedRow colSpan={headers.length + 1}>
                      <div className="expandedDescription">
                        <h3 className="contentTitle">{getRowTitle(row.id)}</h3>
                        <div>{getRowDescription(row.id)}</div>
                      </div>
                    </TableExpandedRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </>
  );
};

export default RegTableComponent;
