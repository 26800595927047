import { ListItem, UnorderedList } from "carbon-components-react";
import * as React from "react";

const Aeob = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            The Advanced Explanations of Benefits (AEOB) has been placed on-hold
            until further notice.
          </ListItem>
          <ListItem>
            The AEOB requires insurers and providers to provide patients with an
            estimate of out-of-pocket expenses prior to any pre-scheduled
            service. This estimate would include estimated provider charge(s)
            for the scheduled service(s), the allowed amount, and any
            out-of-pocket expenses the member would be responsible for based on
            their current plan information, such as deductible and cost-share.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            Plans should await further guidance from the federal departments
            before taking any action on this requirement.
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default Aeob;
