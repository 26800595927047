import { ListItem, UnorderedList, Link } from "carbon-components-react";
import * as React from "react";

const InNetworkFile = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            This file must include rates negotiated with in-network providers
            and be published on the plan's website monthly.
          </ListItem>
          <ListItem>
            The data must span a 90-day period beginning 180 days prior to the
            publication date.
          </ListItem>
          <ListItem>
            The file must be published at the plan level (not the plan sponsor
            level) and be prepared in a machine-readable format (either JSON,
            CSV, or XML).
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            The complexity surrounding production of the in-network file was
            made slightly easier after the federal departments recently
            announced a table of contents feature. Essentially, multiple
            different rate files can be referenced using a table of contents,
            which alleviates the need to combine into a single file. However,
            while this will make it easier to produce a file by the 7/1/2022
            deadline, it does not make it any easier to deliver the price
            comparison tool, which will depend on a single list of rates.
          </ListItem>
          <ListItem>
            The deadline for this requirement was originally 1/1/2022, however,
            the federal departments extended the enforcement deadline to
            7/1/2022. Plans have been provided guidance by the federal
            departments on how to develop the file. See the link below.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Resources:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            <Link href="https://github.com/CMSgov/price-transparency-guide">
              CMS GitHub Price Transparency Rate File Guide
            </Link>
          </ListItem>
          <ListItem>
            <Link href="https://www.cms.gov/healthplan-price-transparency/resources/technical-clarification">
              MRF Technical Clarifications (2/7/2022)
            </Link>
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default InNetworkFile;
