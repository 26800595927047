import * as React from "react";

import { Button } from "carbon-components-react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Chat32 } from "@carbon/icons-react";

const ExpertButtonComponent = () => {
  return (
    <>
      <AnchorLink to="/#contact">
        <Button className="helpButton" renderIcon={Chat32}>
          Questions? Email an expert
        </Button>
      </AnchorLink>
    </>
  );
};

export default ExpertButtonComponent;
