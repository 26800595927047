import { ListItem, UnorderedList } from "carbon-components-react";
import * as React from "react";

const Idr = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            IDR may be triggered by the out-of-network provider/facility after
            the conclusion of the 30-day Open Negotiation period, which begins
            after the plan pays or denies a claim subject to balance billing
            protections.
          </ListItem>
          <ListItem>
            If an out-of-network provider disagrees with the plan’s
            reimbursement amount, a provider may utilize IDR to attempt to
            collect a higher reimbursement.
          </ListItem>
          <ListItem>
            This type of arbitration is referred to as baseball style, because
            the arbiter may only select either the provider’s offer or the
            plan’s. They may not split the difference. The losing party pays the
            arbitration fees (about $300-600 per case).
          </ListItem>
          <ListItem>
            As it currently stands, the arbiter is not obligated to select one
            offer over another. Therefore, plans must be prepared to justify why
            the QPA, or whichever offer they submit to IDR, is the correct
            amount. This was a recent change based on a court ruling in the
            United States District Court for the Eastern District of Texas. The
            arbiter was previously directed by the federal departments through
            rulemaking to select the offer closest to the QPA, unless an
            argument could be made to the contrary. However, in a reversal, the
            Court's ruling in Texas Medical Ass'n, et al. v. HHS mandates that
            the QPA not be the default amount. There are still several cases on
            this topic pending as well as the potential for appeal.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            Plan's must establish a process to receive Open Negotiation requests
            from providers and any subsequent IDR requests. There are tight
            turnaround timelines for the IDR process. At a minimum, we recommend
            plans carefully consider who will respond to these requests and a
            way to ensure the timelines are met.
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default Idr;
