import { ListItem, UnorderedList, Link } from "carbon-components-react";
import * as React from "react";

const Nqtl = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            Plan sponsors that offer mental health or substance use disorder
            benefits must provide a comparative analysis upon request to the
            Department of Labor (DOL) demonstrating parity with medical/surgical
            benefits.
          </ListItem>
          <ListItem>
            More specifically, plan sponsors must identify and demonstrate
            parity regarding any non-quantifiable treatment limitations (NQTLs).
            NQTLs are non-numerical limits on the scope or duration of benefits
            for treatment.{" "}
          </ListItem>
          <ListItem>
            Examples of NQTLs include preauthorization, concurrent review,
            standards for provider admission to the network, network adequacy,
            reimbursement methods, step therapy, and formulary design.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            In January 2022, the DOL released its year-end report to Congress
            claiming that enforcing compliance with NQTL will be a
            "top-priority" in 2022. We recommend plan sponsors carefully review
            their approach to comparative analysis and mental health parity
            given the complexity of these regulations and the number of plans
            that have already failed to produce a sufficient analysis.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Resources:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            <Link href="https://www.linkedin.com/pulse/dol-coming-your-nqtl-analysis-heres-everything-self-funded-/">
              Read our in-depth summary of NQTL
            </Link>
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default Nqtl;
