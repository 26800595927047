import { ListItem, UnorderedList } from "carbon-components-react";
import * as React from "react";

const ProviderDirectory = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            Plans must verify provider data accuracy every 90 days (including
            the provider’s name, address, specialty, and phone and digital
            contact information) and remove providers that cannot be verified in
            a timely manner.
          </ListItem>
          <ListItem>
            Plans must update the directory within 48 hours of notification of a
            provider or facility leaving the network.
          </ListItem>
          <ListItem>
            If a provider is listed in the directory in error, the plan must pay
            any member claims resulting from this error as in-network.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            Maintaining an accurate provider directly is extremely difficult. We
            recommend plans carefully evaluate their current processes and
            consider implementing a robust program to proactively identify
            errors. Given this new regulation, it's no longer enough to blame
            the providers for bad data. The plan must take responsibility for
            accurately representing their network.
          </ListItem>
          <ListItem>
            Regarding processing errors as in-network, plans may find it
            difficult to build a proactive process that adjudicates claims as
            in-network when mistakes are made with their directory. Instead, we
            recommend this situation be resolved through an appeal.
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default ProviderDirectory;
