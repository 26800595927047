import { ListItem, UnorderedList } from "carbon-components-react";
import * as React from "react";

const TransparencyTool = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            Plans must make a price transparency tool available to members. This
            tool must reveal the estimated out-of-pocket cost to a member for a
            specific item or service rendered by a provider at a designated
            facility if contract data is available.
          </ListItem>
          <ListItem>
            The estimate must consider data specific to the member, such as
            their deductible and cost-share. The plan must also present certain
            information even if the provider is out-of-network.
          </ListItem>
          <ListItem>
            This requirement applies to 500 shoppable services designated by the
            federal departments in 1/1/2023, and all remaining services in
            1/1/2024.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            We recommend beginning implementation as soon as possible. This
            requirement is exceedingly complex. Even if a plan already has a
            tool in place, most tools do not meet the threshold required to be
            compliant with this requirement. For example, most tools do not
            allow a member to look up any item or service and receive an
            accurate quote down to the level of a specific provider at a
            specific facility.
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default TransparencyTool;
