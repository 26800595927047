import { ListItem, UnorderedList } from "carbon-components-react";
import * as React from "react";

const IdCards = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            Member ID cards must clearly display the member’s deductible (if
            applicable), out-of-pocket maximums, and the customer service
            telephone number and website address.
          </ListItem>
          <ListItem>
            The deductible and out-of-pocket information must display both
            in-network and out-of-network, as applicable to the plan.
          </ListItem>
          <ListItem>
            This information must be provided in both physical and electronic
            format.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            This is a relatively straightforward requirement, however, it's
            worth highlighting that plans are required to have both physical and
            electronic ID cards. Some plans may not have an electronic
            equivalent in place yet, which this law will now require.
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default IdCards;
