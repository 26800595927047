import { ListItem, UnorderedList, Link } from "carbon-components-react";
import * as React from "react";

const BalanceBillingNotice = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            Plans must notify members of their balance billing rights when
            sending an Explanation of Benefits (EOB) that contains a claim
            subject to balance billing protections.
          </ListItem>
          <ListItem>
            The departments have provided a model notice (see below).
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            We recommend plans use the model notice for now. In some cases, it
            may be worthwhile to update parts of the model language for clarity,
            because the language as written is currently ambiguous. For example,
            plans may want to communicate that the EOB the member is viewing has
            a claim in which the protections apply, which is not clearly stated
            in the model notice. Keep in mind, there may be additional
            rulemaking forthcoming that will further clarify this requirement.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Resources:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            <Link href="https://www.dol.gov/sites/dolgov/files/ebsa/laws-and-regulations/laws/no-surprises-act/surprise-billing-model-notice.docx#:~:text=%E2%80%9CSurprise%20billing%E2%80%9D%20is%20an%20unexpected,out%2Dof%2Dnetwork%20provider">
              DOL Surprise Billing Model Notice
            </Link>
          </ListItem>
          <ListItem>
            <Link href="https://www.cms.gov/files/document/memo-no-surprises-act-phone-number-and-website-url-clean-508-mm2.pdf">
              CMS Memo on Required Contact Information for Consumer Notices
            </Link>
          </ListItem>
          <ListItem>
            LaunchPad Health has a template that clients may use to fulfill this
            requirement.
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default BalanceBillingNotice;
