import * as React from "react";

import {
  Content,
  Accordion,
  AccordionItem,
  ListItem,
  UnorderedList,
  OrderedList,
  Link,
} from "carbon-components-react";
import { Launch20 } from "@carbon/icons-react";

const KeyRequirements = () => {
  return (
    <>
      <div id="keyRequirements" className="anchorOffset" />
      <Content>
        <div className="bx--row">
          <div className="bx--col-lg-2 bx--col-md-0 bx--col-sm-0"></div>
          <div className="bx--col-lg-12 bx--col-md-8 bx--col-sm-4">
            <h2 className="spacing09 h2">Key Requirements for Health Plans</h2>
            <p className="spacing05">
              The Transparency in Coverage (TiC) rules and the Consolidated
              Appropriations Act of 2021, which includes the No Surprises Act,
              Mental Health Parity, and Drug Cost Reporting, collectively
              referred to as "transparency," are the most impactful industry
              regulations since the ACA. There are numerous new administrative
              requirements for plans, TPAs, networks, and their vendors.
            </p>
            <Accordion align="start">
              <AccordionItem title="Qualified Payment Amount (QPA)">
                <div className="contentBlock">
                  <h3 className="contentTitle">
                    Qualified Payment Amount (QPA)
                  </h3>
                  <p className="contentText">
                    The Qualified Payment Amount (QPA) is used to determine
                    member cost-share for items and services in which balance
                    billing is prohibited under the No Surprises Act. The QPA is
                    also a critical factor during arbitration. Plans must also
                    present the QPA to providers in the Explanation of Payment
                    (EOP), and be prepared to clarify how it was calculated upon
                    request.
                  </p>{" "}
                  <h5 className="contentSubtitle">
                    Circumstances when the QPA applies:{" "}
                  </h5>{" "}
                  <OrderedList className="contentText paddingLeft09">
                    <ListItem>
                      Member receives out-of-network emergent care and the
                      services are otherwise covered by the plan.
                    </ListItem>{" "}
                    <ListItem>
                      Member receives non-emergent care provided in a
                      participating facility by an out-of-network provider.
                    </ListItem>
                  </OrderedList>
                  <h5 className="contentSubtitle">
                    How the QPA is determined:
                  </h5>{" "}
                  <OrderedList className="paddingLeft09 contentText">
                    <ListItem>
                      All-Payer Model Agreement (if the plan chooses to
                      participate or state law requires it based on plan type).
                    </ListItem>
                    <ListItem>
                      State law (if the plan chooses to participate or state law
                      requires it based on plan type).
                    </ListItem>
                    <ListItem>
                      Median contracted rate for the item/service in a specific
                      geography. If there is insufficient contract data, the
                      plan would use an eligible third party database or other
                      means, which LaunchPad Health can help identify.
                    </ListItem>
                  </OrderedList>
                </div>
              </AccordionItem>
              <AccordionItem title="Independent Dispute Resolution (IDR)">
                <div className="contentBlock">
                  <h3 className="contentTitle">
                    Independent Dispute Resolution (IDR)
                  </h3>
                  <p className="contentText">
                    Independent Dispute Resolution (IDR) may be triggered by the
                    plan or the out-of-network provider/facility after the
                    conclusion of the 30-day open negotiation period, which
                    begins after plans pay or deny the claim. If an
                    out-of-network provider disagrees with the plan’s
                    reimbursement amount, a provider can utilize IDR to attempt
                    to collect a higher reimbursement. The IDR entity is
                    responsible for ultimately deciding the final payment owed
                    to the provider. The arbiter cannot split the difference
                    between offers. One side wins and the other loses. Loser
                    pays the arbitration fees.
                  </p>
                </div>
              </AccordionItem>
              <AccordionItem title="Provider Directory Accuracy">
                <div className="contentBlock">
                  <h3 className="contentTitle">Provider Directory Accuracy</h3>
                  <p className="contentText">
                    Plans must include the provider name, address, specialty,
                    phone and digital contact information (e.g. email) in their
                    directory and verify this information every 90 days.
                    Providers or facilities that cannot be verified must be
                    removed in a timely mannner. In addition, the plan must
                    update their provider directory within 2 days of
                    notification from a provider of any changes. The provider
                    directory must be available on a public website and include
                    both in-network professionals and facilities. If the plan
                    incorrectly advises a member that a provider is in-network,
                    the plan must limit the member’s cost share to what they
                    would pay at the in-network benefit level.
                  </p>
                </div>
              </AccordionItem>
              <AccordionItem title="Continuity of Care">
                <div className="contentBlock">
                  <h3 className="contentTitle">Continuity of Care</h3>
                  <p className="contentText">
                    If a member is receiving care from an in-network provider or
                    facility and the provider or facility status changes to
                    out-of-network, the plan must notify the member of the
                    provider/facility’s termination from the network and their
                    right to continued transitional care for a period of time if
                    they meet the criteria established in the NSA law. The
                    member can elect to continue to receive care from the
                    provider at the in-network cost-sharing amount for 90 days,
                    or the date when the member is no longer a continuing care
                    patient, whichever is sooner.
                  </p>
                </div>
              </AccordionItem>
              <AccordionItem title="Machine Readable Rate Files">
                <div className="contentBlock">
                  <h3 className="contentTitle">Machine Readable Rate Files</h3>
                  <p className="contentText">
                    Plans must produce three machine readable rate files.
                    However, please note the Prescription Drug rate file has
                    been indefinitely delayed by the federal departments.
                  </p>

                  <h5 className="contentSubtitle">In-Network Rate File:</h5>
                  <p className="contentText">
                    This file should include rates negotiated with in-network
                    providers and be published on the plan's website. It needs
                    to be updated monthly, and include data for a 90-day period
                    beginning 180 days prior to the publication date. Note, this
                    file is published at the plan level (not the plan sponsor
                    level).
                  </p>

                  <h5 className="contentSubtitle">Allowed Amount Rate File:</h5>
                  <p className="contentText">
                    This file should include historical allowed amounts and
                    billed charges for out-of-network claims and be published on
                    the plan's website. It needs to be updated monthly, and
                    include data for a 90-day period beginning 180 days prior to
                    the publication date. Note, this file is published at the
                    plan sponsor level.
                  </p>

                  <h5 className="contentSubtitle">
                    Prescription Drug Rate File:
                  </h5>
                  <p className="contentText">
                    The federal departments have placed this provision on-hold
                    for now. We advise plans to withhold implementation until
                    further guidance is received.
                  </p>
                </div>
              </AccordionItem>
              <AccordionItem
                title="
Non-Quantitative Treatment Limitation Reporting (NQTL)"
              >
                <div className="contentBlock">
                  <h3 className="contentTitle">
                    Non-Quantitative Treatment Limitation Reporting (NQTL)
                  </h3>
                  <p className="contentText">
                    Plan sponsors that offer mental health or substance use
                    disorder benefits must provide a comparative analysis upon
                    request to the Department of Labor (DOL) demonstrating
                    parity with medical/surgical benefits. This requirement went
                    into effect on 2/10/2021.
                  </p>
                  <p className="contentText">
                    More specifically, plan sponsors must identify and
                    demonstrate parity regarding any non-quantifiable treatment
                    limitations (NQTLs). NQTLs are non-numerical limits on the
                    scope or duration of benefits for treatment. Examples of
                    NQTLs include preauthorization, concurrent review, standards
                    for provider admission to the network, network adequacy,
                    reimbursement methods, step therapy, and formulary design.
                  </p>
                  <p className="contentText">
                    Plans are required to provide extensive documentation and a
                    narrative analysis demonstrating parity from both a policy
                    and day-to-day operational perspective. The analysis must be
                    documented in advance and updated on a regular basis as
                    changes are made to the plan.
                  </p>
                  <p className="contentText">
                    In January 2022, the DOL released its year-end report to
                    Congress claiming that enforcing compliance with NQTL will
                    be a "top-priority" in 2022. We recommend plan sponsors
                    carefully review their approach to comparative analysis and
                    mental health parity given the complexity of these
                    regulations and the number of plans that have already failed
                    to produce a sufficient analysis.
                  </p>
                  <Link
                    href="https://www.linkedin.com/pulse/dol-coming-your-nqtl-analysis-heres-everything-self-funded-/"
                    className="spacing03"
                    size="lg"
                    renderIcon={Launch20}
                  >
                    Read our in-depth summary of NQTL
                  </Link>
                </div>
              </AccordionItem>
              <AccordionItem title="Price Transparency Tool">
                <div className="contentBlock">
                  <h3 className="contentTitle">Price Transparency Tool</h3>
                  <p className="contentText">
                    Plans must make a price transparency tool available to
                    members. This tool must reveal the estimated out-of-pocket
                    cost to a member for a specific item or service rendered by
                    a provider at a designated facility, if contract data is
                    available. The estimate must take into account updated
                    member data, such as deductible. The plan must also present
                    certain information even if the provider is out-of-network.
                    There are also requirements outlining the manner in which
                    this data must be presented to the member. This requirement
                    applies to 500 shoppable services designated by the federal
                    departments in Jan 2023, and all remaining services in Jan
                    2024
                  </p>
                </div>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="bx--col-lg-2 bx--col-md-0 bx--col-sm-0"></div>
        </div>
      </Content>
    </>
  );
};

export default KeyRequirements;
