import { ListItem, UnorderedList } from "carbon-components-react";
import * as React from "react";

const GagClauseProhibition = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            This requirement bans clauses in contracts that would prevent plans
            from accessing and sharing cost and quality of care information.
          </ListItem>
          <ListItem>
            The federal departments don't intend to release rulemaking on this
            requirement, because they have already shared significant guidance
            given previous laws on NQTL. However, they will require an annual
            attestation, which is discussed later under “Annual Attestation on
            Gag Clauses”.”
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            We interpret this requirement to mean gag clauses must be removed,
            even if the parties agree to no longer recognize this provision
            within an existing contract. Plans should begin this review as soon
            as possible given the potentially significant work to adjust
            affected contracts.
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default GagClauseProhibition;
