import { ListItem, UnorderedList } from "carbon-components-react";
import * as React from "react";

const SurpriseBilling = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            Balance billing is prohibited in cases of (1) emergency care
            rendered by an out-of-network provider and/or facility, (2)
            non-emergency services rendered by an out-of-network provider at an
            in-network facility, and (3) out-of-network air ambulance services.
          </ListItem>
          <ListItem>
            These requirements apply to reference-based-pricing plans in cases
            of emergency care and air ambulance (1 and 3 above).
          </ListItem>
          <ListItem>
            Emergency care is defined as acute symptoms, such as severe pain,
            that would cause a prudent layperson to reasonably assume the
            individual's health is in serious jeopardy absent immediate medical
            attention.
          </ListItem>
          <ListItem>
            Plans may make any initial payment they choose on these claims, or
            even deny. If the provider disagrees with the payment/denial, they
            cannot balance bill the member. Providers must instead negotiate
            with the plan directly. If both parties cannot come to agreement,
            the provider may initiate the Independent Dispute Resolution (IDR)
            process.
          </ListItem>
          <ListItem>
            For certain pre-scheduled services, an out-of-network provider may
            ask a patient to sign a Patient Waiver prior to receiving
            out-of-network care. This waives the member's balance billing
            rights. In these instances, the provider must send a copy of the
            waiver to the plan and the member, and keep a record of the waiver
            for seven years.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            This is a positive development for plans because it helps minimize
            member friction resulting from balance billing. However, plans must
            ensure they have a strong process in place to handle the Open
            Negotiation and IDR processes if the provider disagrees with the
            plan's reimbursement.
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default SurpriseBilling;
