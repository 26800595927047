import * as React from "react";

import { Content, Button } from "carbon-components-react";
import { ArrowRight20 } from "@carbon/icons-react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const TransparencyHelp = () => {
  return (
    <>
      <div id="experts" className="anchorOffset" />
      <Content className="expertsCover">
        <div className="bx--row expertsBackground">
          <div className="bx--col-lg-2 bx--col-md-0 bx--col-sm-0"></div>
          <div className="bx--col-lg-12 bx--col-md-8 bx--col-sm-4 expertsContent">
            <h2 className="h2 spacing09">Experts ready to help</h2>
            <div>
              <p className="contentText">
                Let's setup a free one-hour consultation to discuss your
                transparency needs. Whether you have one question, or need
                end-to-end implementation, put our expertise to work for you.
              </p>
              <AnchorLink to="/#contact">
                <Button renderIcon={ArrowRight20} className="contentText">
                  Contact Us
                </Button>
              </AnchorLink>
            </div>
          </div>
          <div className="bx--col-lg-2 bx--col-md-0 bx--col-sm-0"></div>
        </div>
      </Content>
    </>
  );
};

export default TransparencyHelp;
