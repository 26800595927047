import { ListItem, UnorderedList } from "carbon-components-react";
import * as React from "react";

const GagClauseAttestation = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            This requirement, which has yet to be finalized through rulemaking,
            will require plans to submit an annual attestation claiming they
            have no gag clauses in place.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            The federal departments have yet to clarify how they would like
            these attestations to be submitted and what should be included.
            However, we advise plans to begin thinking about which individual
            will coordinate submission, and ensure they are educated on their
            responsibilities as rulemaking is released.
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default GagClauseAttestation;
