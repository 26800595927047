import { ListItem, UnorderedList, Link } from "carbon-components-react";
import * as React from "react";

const ProviderNotice = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            Plans must share certain information with the provider upon making
            an initial payment for a claim subject to balance billing
            protections.
          </ListItem>
          <ListItem>
            Specifically, if a plan determines a Qualified Payment Amount (QPA)
            applies to the claim, they must state that they have made this
            determination and reveal the QPA amount.
          </ListItem>
          <ListItem>
            Plans must also provide a contact that providers may reach to
            initiate the Open Negotiation process.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            This is a relatively straightforward requirement. The most important
            element for plans will be correctly identifying the payments in
            which these additional notifications to the provider would apply.{" "}
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Resources:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            LaunchPad Health has a template that clients may use to fulfill this
            requirement.
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default ProviderNotice;
