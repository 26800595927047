import { ListItem, UnorderedList, Link } from "carbon-components-react";
import * as React from "react";

const PrescriptionFile = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            The Prescription Drug Rate File has been placed on-hold until
            further notice.
          </ListItem>
          <ListItem>
            The federal departments issued final rules on the Prescription Drug
            Rate File in Nov 2020 along with the other rate files, but later
            postponed this requirement in an{" "}
            <Link href="https://www.dol.gov/sites/dolgov/files/EBSA/about-ebsa/our-activities/resource-center/faqs/aca-part-45.pdf">
              Aug 2021 FAQ update
            </Link>
            . The file would have required plans to disclose negotiated rates
            and historical net prices for prescription drugs. The departments
            noted several stakeholder concerns regarding conflicting
            requirements between this provision and the CAA provision requiring
            reporting on pharmacy benefits and drug costs.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            Plans should await further guidance from the federal departments
            before taking any action on this requirement.
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default PrescriptionFile;
