import { ListItem, UnorderedList, Link } from "carbon-components-react";
import * as React from "react";

const Qpa = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            The QPA is the median of contracted rates for a specific item or
            service in a defined service area and adjusted for inflation.
          </ListItem>
          <ListItem>
            This is the amount plans use to calculate member cost-share for
            claims in which balance billing is prohibited. However, plans do not
            need to pay this amount to the provider. They may pay more, less, or
            deny the claim.
          </ListItem>
          <ListItem>
            The QPA is utilized when an all-payer model agreement and state
            balance billing laws do not apply, which is most often the case for
            self-funded ERISA plans.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            The QPA is complex and requires careful consideration depending on
            how your plan is structured. Many plans have differing strategies
            for how they derive their QPA. Some have enough contract data to
            derive the amount following the rules laid out by the federal
            departments. However, many do not have enough data or any at all,
            especially in the case of reference-based plans. LaunchPad Health
            can help you determine the right approach for your plan.
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Resources:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            <Link href="https://www.cms.gov/CCIIO/Programs-and-Initiatives/Other-Insurance-Protections/CAAQualifying-Payment-Amount-Calculation-Methodology.pdf">
              CMS Qualified Payment Amount Calculation Methodology
            </Link>
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default Qpa;
