import { ListItem, UnorderedList, Link } from "carbon-components-react";
import * as React from "react";

const PatientWaiver = () => {
  return (
    <>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Key Points:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            An out-of-network provider may request that the patient waive their
            balance billing rights under two scenarios. First, once the patient
            has been stabilized after receiving emergency care, and second,
            prior to a previously scheduled out-of-network appointment.
          </ListItem>
          <ListItem>
            The provider must submit a copy of this waiver to both the patient
            and the plan. The provider must also keep a record for seven years.
          </ListItem>
          <ListItem>
            There are instances when providers cannot request a waiver, such as
            when a patient is receiving ancillary services (anesthesiology,
            pathology, and radiology).
          </ListItem>
        </UnorderedList>
      </div>
      <div className="contentBlock">
        <h5 className="contentSubtitle">Our Take:</h5>
        <UnorderedList className="paddingLeft09">
          <ListItem>
            It's not yet clear how often providers will result to using this
            waiver, and if they do, how they will share this information with
            plans. We recommend waiting to evaluate how this works in practice
            before developing a process.
          </ListItem>
        </UnorderedList>
      </div>
    </>
  );
};

export default PatientWaiver;
